<template>
	<div>
		<p class="ppp">文章发布</p>
		<br />
		<el-form :model="form">
			<el-form-item label="标题:">
				<el-input v-model="form.title" placeholder="请输入文章标题(5-20字)"
					style="width: calc(60vw - 300px);float:left;">
				</el-input>
			</el-form-item>
			<el-form-item label="作者:">
				<el-select v-model="form.author" placeholder="请选择发布文章的作者" style="width: calc(60vw - 300px);float:left;">
					<el-option class="option66" v-for="item in options" :key="item.GUserID" :label="item.GuaName"
						:value="item.GUserID">
					</el-option>
				</el-select>
			</el-form-item>
      <el-form-item label="全国发布:" >
        <el-radio-group v-model="form.quanguo" style="display: flex;margin-top: 14px;" >
          <el-radio :label="0" >否</el-radio>
          <el-radio :label="1" >是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="省市区:" v-if='this.form.quanguo == 0'>
        <div style="float: left;">
          <el-select v-model="form.province" class="select" size="medium" clearable placeholder="请选择" @change="city">
            <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
          <el-select v-model="form.city" class="select" size="medium" clearable placeholder="请选择" @change="area">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
          <el-select v-model="form.area" class="select" size="medium" clearable placeholder="请选择">
            <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
      </el-form-item>
			<el-form-item label="内容:"></el-form-item>
			<el-form-item>
				<editor v-model="form.content" @change="change" @img="img"
					style="overflow:auto;width: calc(100vw - 300px);"></editor>
			</el-form-item>
		</el-form>
		<br />
		<el-button type="success" @click="add()">提交</el-button>
	</div>
</template>

<script>
	import {
		pushNews,
		pushUser,
    ssq,
	} from '@/api/adminApi.js'
	import editor from '@/views/utils/editor'
	export default {
		components: {
			editor
		},
		data() {
			return {
				form: { //提交文章的form表单
          quanguo  : 1,
					title    : '',
					author   : '',
					content  : '',
          province : '',
          city     : '',
          area     : '',
				},
				options:       [], //发布文章作者数组
				urlArr:        [], //图片地址
        options:       [], //发布文章作者数组
        provinceList:  [], //图片地址
        cityList:      [], //发布文章作者数组
        areaList:      [], //图片地址
			}
		},
		created() {
			this.pushUser(); //获取发布文章的作者列表
      this.province() // 总后台省市区学校接口
		},
		methods: {
      ssqList() {
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize // 每页条数
        }
        setUserList(data).then(res => {
          this.data = res.data.List // 用户列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
			change(data) { //富文本回调函数，修改后的文章内容
				this.form.content = data;
			},
			img(url) { //插入图片后返回的图片地址
				this.urlArr.push(url);
			},
			async add() { //点击提交按钮

				if (this.form.title.length < 5) {
					this.$message.error('请补全标题 不能小于5个字');
				} else if (!this.form.author) {
					this.$message.error('请选择作者');
				} else if (this.form.content.length < 10) {
					this.$message.error('请补齐文章内容 不能小于10个字');
				} else if(this.form.quanguo == 0 && this.form.province == "" && this.form.city == "" && this.form.area == ""){
          this.$message.error('请至少选择省');
        }else {
					var div = document.createElement('div'); //创建一个div
					div.innerHTML = this.form.content; //将文章内容添加到div中
					var img = div.getElementsByTagName('img'); //查找div中所有img标签
          img = Array.from(img)//把img变成数组才能遍历
					let imgStr = ''; //设置拼接图片地址的字符串

					img.forEach((item, index) => { //循环查找到的img标签数组
						if (img.length - 1 > index) { //进行拼接
							imgStr = imgStr + item.src + ',';
						} else {
							imgStr = imgStr + item.src;
						}
					})
					var author = this.options.filter(item => item.GUserID == this.form.author); //根据选择的作者id获取作者信息
					const data = { //请求携带的参数
						GUserID: author[0].GUserID, //作者id
						author: author[0].GuaName, //作者昵称
						title: this.form.title, //标题
						content: this.form.content, //文章内容
						type: img.length >= 3 ? 3 : (img.length == 0 ? 3 : (img.length == 1 ? 2 : 2)), //文章类型
						arrImage: imgStr ,//拼接的图片地址
            province: this.form.province !="" ?  this.form.province : 0,//省
            city: this.form.city !="" ? this.form.city : 0 ,//市
            area: this.form.area !="" ? this.form.area : 0 ,//区
            quanguo: this.form.quanguo  //是否全国
					}
					let res = await pushNews(data); //发送请求
					if (res.status.code == 1) {
						this.$message.success("文章发布成功！");
						location.reload();
					} else {
						this.$message.error(res.status.msg);
					}
				}
			},
			async pushUser() { //获取发布文章的作者列表
				let res = await pushUser(); //请求接口
				this.options = res.data; //获得文章作者数组
			},
      province() { // 总后台省接口
        ssq().then(res => {
          this.provinceList  = res.data.province
        }).catch(err => {
          console.log(err)
        })
      },
      city() { // 总后台市接口
        this.form.city = '';
        this.form.area = '';
        const data={
          pid : this.form.province
        }
        ssq(data).then(res => {
          this.cityList  = res.data.city
        }).catch(err => {
          console.log(err)
        })
      },
      area() { // 总后台区接口
        this.form.area = '';
        const data={
          cid : this.form.city
        }
        ssq(data).then(res => {
          this.areaList  = res.data.area
        }).catch(err => {
          console.log(err)
        })
      },
		}
	}
</script>
<style>
	.w-e-text-container {
		z-index: 100 !important;
	}

	.w-e-toolbar {
		z-index: 200 !important;
	}
</style>
